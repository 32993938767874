<script>
import t from "stores/translations"
import mainView from "stores/mainView"
import { slide } from "svelte/transition"
import friends from "stores/friends"
import ReportsUserPill from "./ReportsUserPill.svelte"
import ReportsAllBalances from "./ReportsAllBalances.svelte"
import ReportsRepayment from "./ReportsRepayment.svelte"
</script>

<style>
.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.empty span {
    text-align: center;
    padding: 16px 0;
}

.user-pills {
    display: flex;
    flex-wrap: wrap;
}

.subtitle {
    color: #545454;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    margin: 16px 0 8px 0;
}
</style>

<div class="card">
    <div class="card__title">
        <span>{$t.reportsTitle}</span>
        <i class="material-icons" on:click={mainView.toggleReports}>close</i>
    </div>
    <div class="card__content" transition:slide|local={{ duration: 250 }}>
        {#if $friends.length}
            <div class="subtitle">{$t.reportsRelevantUsersSubtitle}</div>
            <div class="user-pills">
                {#each $friends as friend (friend.id)}
                    <ReportsUserPill user={friend}/>
                {/each}
            </div>

            <div class="subtitle">{$t.reportsAllBalancesSubtitle}</div>
            <ReportsAllBalances/>

            <div class="subtitle">{$t.reportsRepaymentSubtitle}</div>
            <ReportsRepayment/>
        {:else}
            <div class="empty">
                <span>{$t.reportsEmpty}</span>
            </div>
        {/if}
    </div>
</div>
