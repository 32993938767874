<script>
import addExpenseStore from "stores/addExpense"
import UserInput from "./UserInput.svelte"
import DebitorInput from "./DebitorInput.svelte"
import { afterUpdate, tick } from "svelte"
import t from "stores/translations"

let user

afterUpdate(async () => {
    if (user && user.id && user.name) {
        addExpenseStore.addDebitor({
            user
        })

        await tick()
        // eslint-disable-next-line require-atomic-updates
        user = {
            name: "",
            id: undefined
        }
    }
})
</script>

<style>
.debitor-input {
    background-color: #eeeeee;
    border-radius: 40px;
    padding: 8px;
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
</style>

<div>
    {#each $addExpenseStore.debitors as debitor}
        <DebitorInput debitor={debitor}/>
    {/each}

    <div class="debitor-input">
        <UserInput bind:value={user} placeholder={$t.addUsersPlaceholder}/>
    </div>
</div>


