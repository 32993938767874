import { writable } from "svelte/store"

function createStore() {
    const { subscribe, update } = writable({
        isAddingExpense: false,
        settingsExpanded: false,
        reportsExpanded: false,
        isAddingDebitor: false,
        simpleMode: true,
        isAddingCreditor: false,
        isAddingExpenseInWidget: false
    })

    return {
        subscribe,
        toggleIsAddingExpense() {
            mixpanel.track("toggleIsAddingExpense")
            return update(state => ({
                ...state,
                isAddingExpense: !state.isAddingExpense,
                isAddingDebitor: false,
                isAddingCreditor: false
            }))
        },
        toggleIsAddingExpenseInWidget() {
            mixpanel.track("toggleIsAddingExpenseInWidget")
            return update(state => ({
                ...state,
                isAddingExpenseInWidget: !state.isAddingExpenseInWidget
            }))
        },
        callToActionCloseAll() {
            mixpanel.track("callToActionCloseAll")
            return update(state => ({
                ...state,
                isAddingExpense: false,
                isAddingDebitor: false,
                isAddingCreditor: false
            }))
        },
        toggleSettings() {
            mixpanel.track("toggleSettings")
            return update(state => ({...state, settingsExpanded: !state.settingsExpanded}))
        },
        openSettings() {
            mixpanel.track("openSettings")
            setTimeout(() => {
                window.scroll({
                    top: document.body.scrollHeight,
                    behavior: "smooth"
                })
            }, 300)
            return update(state => ({...state, settingsExpanded: true, simpleMode: false}))
        },
        toggleReports() {
            mixpanel.track("toggleReports")
            return update(state => ({...state, reportsExpanded: !state.reportsExpanded}))
        },
        toggleIsAddingDebitor() {
            mixpanel.track("toggleIsAddingDebitor")
            return update(state => ({
                ...state,
                isAddingExpense: false,
                isAddingDebitor: !state.isAddingDebitor,
                isAddingCreditor: false
            }))
        },
        toggleSimpleMode() {
            mixpanel.track("toggleSimpleMode")
            return update(state => ({...state, simpleMode: !state.simpleMode}))
        },
        toggleIsAddingCreditor() {
            mixpanel.track("toggleIsAddingCreditor")
            return update(state => ({
                ...state,
                isAddingExpense: false,
                isAddingDebitor: false,
                isAddingCreditor: !state.isAddingCreditor
            }))
        },
        closeExpenseInput() {
            return update(state => ({
                ...state,
                isAddingExpense: false,
                isAddingExpenseInWidget: false
            }))
        }
    }
}

export default createStore()