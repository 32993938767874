<script>
import { removeExpense } from "modules/expense"
import t from "stores/translations"
export let expense

let confirmationIsVisible = false

function remove(expense) {
    removeExpense(expense)
    confirmationIsVisible = false
}

function toggleConfirmation() {
    mixpanel.track("expenseListItemToggleConfirmation")
    confirmationIsVisible = !confirmationIsVisible
}
</script>

<style>
div {
    background-color: #f5f5f5;
    padding: 4px;
}

.expense-item-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.delete-confirmation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

span {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
}

i {
    color: #545454;
    cursor: pointer;
}
</style>

<div>
    {#if confirmationIsVisible}
        <div class="delete-confirmation">
            <span>{$t.confirmDelete}</span>
            <span>
                <i class="material-icons" on:click={toggleConfirmation}>close</i>
                <i class="material-icons" on:click={() => remove(expense)}>check</i>
            </span>
        </div>
    {:else}
        <div class="expense-item-actions" on:click={toggleConfirmation}>
            <i class="material-icons">delete</i>
            <span>{$t.delete}</span>
        </div>
    {/if}
    
</div>
