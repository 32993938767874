<script>
import CollapsedWidget from "./CollapsedWidget.svelte"
import Balance from "./Balance.svelte"
import Settings from "./Settings.svelte"
import Reports from "./Reports.svelte"
import mainView from "stores/mainView"
import t from "stores/translations"
import { scale, slide } from "svelte/transition"
import { bounceOut } from "svelte/easing"
import StartWidget from "./StartWidget.svelte"
import { onMount } from "svelte"
import News from "./News.svelte"

onMount(() => mixpanel.track("MainView"))

function trackAboutClick() {
    mixpanel.track("clickAbout")
}
</script>

<style>
.main-view {
    display: flex;
    justify-content: center;
    margin-top: 64px;
}

.main-view__container {
    width: 100%;
    max-width: 600px;
}

.main-view__simple-mode {
    text-align: center;
    color: #545454;
    font-size: 16px;
    padding: 16px 16px 32px 16px;
    cursor: pointer;
}

.about div {
    display: flex;
}

.about a {
    font-size: 20px;
    line-height: 20px;
    color: black;
    text-decoration: none;
}
</style>

<div class="main-view" transition:scale={{duration: 500, easing: bounceOut}}>
    <div class="main-view__container">
        <News/>
        <Balance/>

        {#if !$mainView.simpleMode}
            <div transition:slide|local={{ duration: 250}}>
                <StartWidget/>
                {#if $mainView.reportsExpanded}
                    <Reports/>
                {:else}
                    <CollapsedWidget text={$t.reportsButton} on:click={mainView.toggleReports}/>
                {/if}
                
                {#if $mainView.settingsExpanded}
                    <Settings/>
                {:else}
                    <CollapsedWidget text={$t.settingsButton} on:click={mainView.toggleSettings}/>
                {/if}
                <div class="card__title about">
                    <span>{$t.about}</span>
                    <div>
                        <i class="material-icons">open_in_new</i>
                        <a href="https://instagram.com/retozurich" target="_new" on:click={trackAboutClick}>retozurich</a>
                    </div> 
                </div>
            </div>
        {:else}
            <div class="main-view__simple-mode" on:click={mainView.toggleSimpleMode}>{$t.mainViewSimpleModeToggle}</div>
        {/if}
    </div>
</div>