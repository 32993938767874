import { writable } from "svelte/store"

const LOCAL_STORAGE_KEY = "LANGUAGE"

const translations = {
    en: {
        addExpenseButton: "Add expense",
        nameInputPlacehoder: "What's your name?",
        headerTitle: "Splitshit",
        addUsersPlaceholder: "Add users...",
        addExpenseTitle: "Add expense",
        addExpenseFieldTotal: "Total: ",
        addExpenseFieldCreditor: "Paid by: ",
        addExpenseFieldPurpose: "Purpose: ",
        save: "Save",
        balanceTitle: "Overview",
        signUpButton: "Get started",
        nameInputPlaceholder: "Enter your name",
        reportsButton: "Reports",
        reportsTitle: "Reports",
        settingsButton: "Settings",
        settingsTitle: "Settings",
        currency: "Currency",
        language: "Language",
        languageEnglish: "English",
        languageGerman: "German",
        delete: "Delete",
        confirmDelete: "Are you sure?",
        addExpenseFieldPurposePlaceholder: "Dining, Drinks, Hotel, etc.",
        reportsEmpty: "Add expenses first.",
        reportsRelevantUsersSubtitle: "Relevant users",
        reportsAllBalancesSubtitle: "Balance ranking",
        reportsRepaymentSubtitle: "Possible repayment",
        userInputCreateUser: name => `Create "${name}"`,
        expenseDefaultPurpose: "Unnamed expense",
        nameInputHint: "Recognize your user name? If you have been invited or use Splitshit already, your user already exists in the system. Click on it!",
        callToActionTitle: "Add new ...",
        callToActionAddExpense: "I paid a bill for a group",
        callToActionAddDebitor: "I lent money to someone",
        callToActionAddCreditor: "I borrowed money from someone",
        addDebitorFieldTotal: "Amount: ",
        addDebitorFieldDebitor: "To: ",
        addDebitorTitle: "Money lent to ...",
        addCreditorTitle: "Money borrowed from ...",
        expensePurposeLent: "Money lent",
        expensePurposeBorrowed: "Money borrowed",
        mainViewSimpleModeToggle: "Show more features",
        addCreditorFieldTotal: "Amount: ",
        addCreditorFieldCreditor: "From: ",
        expenseListShowMore: "Show more",
        removedUser: "Removed user",
        about: "About",
        relevantBalance: "Show in overview",
        balanceModeAll: "Balance among all users",
        balanceModeLastDebitors: "Balance among recent users",
        balanceHint: "among"
    },
    de: {
        addExpenseButton: "Neue Ausgabe",
        nameInputPlacehoder: "Wie ist dein Name?",
        headerTitle: "Splitshit",
        addUsersPlaceholder: "Andere hinzufügen...",
        addExpenseTitle: "Neue Ausgabe",
        addExpenseFieldTotal: "Total: ",
        addExpenseFieldCreditor: "Bezahlt: ",
        addExpenseFieldPurpose: "Zweck: ",
        save: "Speichern",
        balanceTitle: "Übersicht",
        signUpButton: "Starten",
        nameInputPlaceholder: "Gib deinen Namen ein",
        reportsButton: "Abrechnungen",
        reportsTitle: "Abrechnungen",
        settingsButton: "Einstellungen",
        settingsTitle: "Einstellungen",
        currency: "Währung",
        language: "Sprache",
        languageEnglish: "Englisch",
        languageGerman: "Deutsch",
        delete: "Löschen",
        confirmDelete: "Bist du sicher?",
        addExpenseFieldPurposePlaceholder: "Essen, Drinks, Hotel, etc.",
        reportsEmpty: "Füge zuerst eine Ausgabe hinzu.",
        reportsRelevantUsersSubtitle: "Relevante Benutzer",
        reportsAllBalancesSubtitle: "Saldo-Rangliste",
        reportsRepaymentSubtitle: "Mögliche Rückzahlung",
        userInputCreateUser: name => `"${name}" erstellen`,
        expenseDefaultPurpose: "Unbenannte Ausgabe",
        nameInputHint: "Erkennst du deinen Namen? Falls du eingeladen wurdest oder Splitshit schon verwendest, existiert dein Benutzer bereits im System. Klick drauf!",
        callToActionTitle: "Neu hinzufügen ...",
        callToActionAddExpense: "Ich habe die Rechnung für eine Gruppe bezahlt",
        callToActionAddDebitor: "Ich habe jemandem Geld geliehen",
        callToActionAddCreditor: "Ich habe von jemandem Geld geborgt",
        addDebitorFieldTotal: "Betrag: ",
        addDebitorFieldDebitor: "An: ",
        addDebitorTitle: "Geld geliehen an ...",
        addCreditorTitle: "Geld geborgt von ...",
        expensePurposeLent: "Geld geliehen",
        expensePurposeBorrowed: "Geld geborgt",
        mainViewSimpleModeToggle: "Mehr Funktionen anzeigen",
        addCreditorFieldTotal: "Betrag: ",
        addCreditorFieldCreditor: "Von: ",
        expenseListShowMore: "Mehr anzeigen",
        removedUser: "Gelöschter Benutzer",
        about: "Mehr erfahren",
        relevantBalance: "Angezeigte Saldobersicht",
        balanceModeAll: "Zwischen allen Benutzern",
        balanceModeLastDebitors: "Zwischen zuletzt involvierten Benutzern",
        balanceHint: "zwischen"
    }
}

function createStore() {
    const { subscribe, set } = writable(
        translations[localStorage.getItem(LOCAL_STORAGE_KEY)] || translations.de
    )

    return {
        subscribe,
        changeLanguage(language) {
            mixpanel.track("changeLanguage")
            mixpanel.register({ language })
            localStorage.setItem(LOCAL_STORAGE_KEY, language)
            return set(translations[language])
        }
    }
}

export default createStore()