<script>
import AmountDisplay from "./AmountDisplay.svelte"
import repayments from "stores/repayments"
import { slide } from "svelte/transition"
import UserNameDisplay from "./UserNameDisplay.svelte"
</script>

<style>
.repayments__item {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #dddddd;
}

.repayments__item span {
    font-size: 20px;
    flex-basis: 30%;
}

.repayments__item:last-of-type {
    border-bottom: none;
}

.center {
    text-align: center;
    white-space: nowrap;
}

.right {
    text-align: right;
}
</style>

<div class="repayments">
    {#each $repayments as repayment}
        <div class="repayments__item" transition:slide|local={{ duration: 250 }}>
            <span class="left"><UserNameDisplay userId={repayment.from.id}/></span>
            <i class="material-icons">arrow_right_alt</i>
            <span class="center"><AmountDisplay amount={repayment.amount}/></span>
            <i class="material-icons">arrow_right_alt</i>
            <span class="right"><UserNameDisplay userId={repayment.to.id}/></span>
        </div>
    {/each}
</div>