<script>
import time from "stores/time"
export let datetime
$: timeAgo = calculate(new Date(datetime), $time)

function translate(difference, unit) {
    const language = localStorage.getItem("LANGUAGE") || "de"
    difference = Math.floor(difference)
    const translations = {
        en: {
            now: "just now",
            minutes: `${difference} minute${difference > 1 ? "s" : ""} ago`,
            hours: `${difference} hour${difference > 1 ? "s" : ""} ago`,
            days: `${difference} day${difference > 1 ? "s" : ""} ago`,
            weeks: `${difference} week${difference > 1 ? "s" : ""} ago`,
            months: `${difference} month${difference > 1 ? "s" : ""} ago`,
            years: `${difference} year${difference > 1 ? "s" : ""} ago`
        },
        de: {
            now: "gerade eben",
            minutes: `vor ${difference} Minute${difference > 1 ? "n" : ""}`,
            hours: `vor ${difference} Stunde${difference > 1 ? "n" : ""}`,
            days: `vor ${difference} Tag${difference > 1 ? "en" : ""}`,
            weeks: `vor ${difference} Woche${difference > 1 ? "n" : ""}`,
            months: `vor ${difference} Monat${difference > 1 ? "en" : ""}`,
            years: `vor ${difference} Jahr${difference > 1 ? "en" : ""}`
        }
    }

    return translations[language][unit]
}

function calculate(datetime, currentDateTime) {
    const difference = (currentDateTime.valueOf() - datetime.valueOf()) / 1000
    if (difference < 60) {
        return translate(difference, "now")
    } else if (difference < 60 * 60) {
        return translate(difference / 60, "minutes")
    } else if (difference < 60 * 60 * 24) {
        return translate(difference / 60 / 60, "hours")
    } else if (difference < 60 * 60 * 24 * 7) {
        return translate(difference / 60 / 60 / 24, "days")
    } else if (difference < 60 * 60 * 24 * 7 * 4) {
        return translate(difference / 60 / 60 / 24 / 7, "weeks")
    } else if (difference < 60 * 60 * 24 * 30 * 12) {
        return translate(difference / 60 / 60 / 24 / 30, "months")
    } else {
        return translate(difference / 60 / 60 / 24 / 30 / 12, "years")
    }
}
</script>

{timeAgo}