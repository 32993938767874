import { writable } from "svelte/store"
import { getCurrentUser } from "modules/currentUser"
import { getAllUsers } from "modules/users"
import { reject, sumBy, round, find } from "lodash-es"

function createStore() {
    const { subscribe, update, set } = writable({
        purpose: "",
        amount: 0,
        paid_by: {},
        authorId: null,
        debitors: []
    }, set => {
        setInitial(set)
    })

    async function setInitial(set) {
        const currentUser = await getCurrentUser()
        const allUsers = await getAllUsers()
        set({
            purpose: "",
            amount: 0,
            paid_by: currentUser,
            author: currentUser.id,
            debitors: (currentUser.lastDebitors || [currentUser.id]).map(userId => ({
                user: find(allUsers, { id: userId }),
                amount: 0,
                accepted: false
            }))
        })
    }

    function addDebitor(debitor) {
        mixpanel.track("addDebitor")
        update(state => ({
            ...state,
            debitors: [...state.debitors.map(debitor => ({
                ...debitor,
                amount: round(state.amount / (state.debitors.length + 1), 2)
            })), {
                ...debitor,
                amount: round(state.amount / (state.debitors.length + 1), 2)
            }]
        }))
    }

    function removeDebitor(debitor) {
        mixpanel.track("removeDebitor")
        update(state => ({
            ...state,
            debitors: state.debitors.length > 1 ? reject(state.debitors, debitor) : state.debitors
        }))

        updateTotalAmount()
    }

    function setDebitorAmount(debitorToUpdate, amount) {
        mixpanel.track("setDebitorAmount")
        update(state => ({
            ...state,
            debitors: [...state.debitors.map(debitor => {
                if (debitor.user.id === debitorToUpdate.user.id) {
                    return { ...debitor, amount }
                } else {
                    return debitor
                }
            })]
        }))

        updateTotalAmount()
    }

    function updateTotalAmount() {
        update(state => ({
            ...state,
            amount: round(sumBy(state.debitors, "amount"), 2)
        }))
    }

    function setTotalAmount(amount) {
        mixpanel.track("setTotalAmount")
        update(state => ({
            ...state,
            amount,
            debitors: [...state.debitors.map(debitor => ({
                ...debitor,
                amount: round(amount / state.debitors.length, 2)
            }))]
        }))
    }

    return {
        subscribe,
        set,
        update,
        reset: () => setInitial(set),
        addDebitor,
        removeDebitor,
        setDebitorAmount,
        setTotalAmount
    }
}

export default createStore()