<script>
import { some } from "lodash-es"
import reports from "stores/reports"
import UserNameDisplay from "./UserNameDisplay.svelte"

export let user
$: checked = some($reports, user)
</script>

<style>
.user-pill {
    cursor: pointer;
    color: #545454;
    background-color: #eeeeee;
    border-radius: 40px;
    padding: 8px 16px 8px 8px;
    margin: 0 8px 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.user-pill.checked {
    color: black;
}

i {
    margin: 0 8px;
}
</style>

<div class="user-pill" on:click={() => reports.toggleUser(user)} class:checked>
    <i class="material-icons">{checked ? "check_box" : "check_box_outline_blank"}</i>
    <span><UserNameDisplay userId={user.id}/></span>
</div>