<script>
import allBalances from "stores/allBalances"
import AmountDisplay from "./AmountDisplay.svelte"
import { slide } from "svelte/transition"
import UserNameDisplay from "./UserNameDisplay.svelte"
</script>

<style>
.all-balances__item {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #dddddd;
}

.all-balances__item span {
    font-size: 20px;
}

.all-balances__item:last-of-type {
    border-bottom: none;
}

.positive {
    color: #217325
}

.negative {
    color: #ff0000
}
</style>

<div class="all-balances">
    {#each $allBalances as user}
        <div class="all-balances__item" transition:slide|local={{ duration: 250 }} class:positive={user.balance > 0} class:negative={user.balance < 0}>
            <span><UserNameDisplay userId={user.id}/></span>
            <span>
                <AmountDisplay amount={user.balance}/>
            </span>
        </div>
    {/each}
</div>