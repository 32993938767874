import { derived } from "svelte/store"
import fingerprintStore from "./fingerprint"
import db from "modules/firebase-db"
import { find, map, includes } from "lodash-es"

export default derived(fingerprintStore, ($fingerprintStore, set) => {
    // TODO: more efficient query
    const usersRef = db.child("users")
    usersRef.on("value", snapshot => {
        const users = map(snapshot.val(), (user, id) => ({
            ...user,
            id
        }))

        const currentUser = find(users, user => (
            includes(map(user.devices), $fingerprintStore)
        ))

        if (currentUser) {
            mixpanel.identify(currentUser.id)
            mixpanel.register({name: currentUser.name})
        }
        
        set(currentUser || false)
    })

    return () => {
        usersRef.off()
    }
})