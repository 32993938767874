import db from "./firebase-db"
import getFingerprint from "modules/fingerprint"
import { map, includes, find, capitalize } from "lodash-es"

export async function createNewCurrentUser(name) {
    const fp = await getFingerprint()
    const newUserRef = db.child("users").push()

    mixpanel.track("createNewCurrentUser", { name })
    
    newUserRef.set({
        name: capitalize(name),
        newsLevel: 0
    }, () => {
        newUserRef.child("devices").push().set(fp)
    })
}

export async function linkCurrentUser(user) {
    const fp = await getFingerprint()
    db.child(`users/${user.id}/devices`).push().set(fp)

    mixpanel.track("linkCurrenUser", {
        name: user.name,
        id: user.id
    })
}

export async function setNewsLevel(user, level) {
    db.child(`users/${user.id}/newsLevel`).set(level)

    mixpanel.track("dismissNews", {
        level
    })
}

export async function getCurrentUser() {
    const fp = await getFingerprint()

    return new Promise(resolve => {
        db.child("users").once("value", snapshot => {

            const users = map(snapshot.val(), (user, id) => ({
                ...user,
                id
            }))

            const currentUser = find(users, user => (
                includes(map(user.devices), fp)
            ))

            resolve(currentUser)
        })
    })
}