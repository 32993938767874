import { writable } from "svelte/store"

const LOCAL_STORAGE_KEY = "LAST_CURRENCY"
const defaultValue = {
    symbol: "CHF",
    rate: 1.00,
    img: "🇨🇭"
}

function getLastCurrency() {
    try {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || defaultValue
    } catch (error) {
        return defaultValue
    }
}

function createStore() {
    const { subscribe, set, update } = writable(getLastCurrency())

    return {
        subscribe,
        setCurrency(value) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value))
            set(value)
        },
        quickSwitch() {
            mixpanel.track("changeCurrencyQuickSwitch")
            update(store => (
                store.symbol === defaultValue.symbol ? getLastCurrency() : defaultValue
            ))
        }
    }
}

export default createStore()