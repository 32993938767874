import { derived } from "svelte/store"
import relevantUsers from "./reports"
import expenses from "./expenses"
import { map, filter, sumBy, some, orderBy } from "lodash-es"

export default derived([relevantUsers, expenses], ([$relevantUsers, $expenses]) => {

    return orderBy(map(
        $relevantUsers,
        user => {
            const amountPaid = sumBy(
                filter($expenses, expense => expense.paid_by.id === user.id), expense => (
                    sumBy(filter(expense.debitors, debitor => (
                        some($relevantUsers, { id: debitor.user.id })
                    )), "amount")
                )
            )

            const amountConsumed = sumBy(
                filter($expenses, expense => (
                    some($relevantUsers, { id: expense.paid_by.id })
                )), expense => (
                    sumBy(filter(expense.debitors, debitor => (
                        debitor.user.id === user.id
                    )), "amount")
                )
            )

            return {
                ...user,
                balance: amountPaid - amountConsumed
            }
        }
    ), "balance", "desc")
})