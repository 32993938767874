import firebase from "firebase/app"
import "firebase/database"

const HOSTING_DOMAIN = "splitshit.com"

const firebaseConfig = {
    apiKey: "AIzaSyCUdDzpILTaOWgrLjhn5tmFziROsO3xwr4",
    authDomain: "splitshit2019.firebaseapp.com",
    databaseURL: "https://splitshit2019.firebaseio.com",
    projectId: "splitshit2019",
    storageBucket: "splitshit2019.appspot.com",
    messagingSenderId: "482446591352",
    appId: "1:482446591352:web:25bfe2c3b206a353"
}	

firebase.initializeApp(firebaseConfig)
export default firebase.database()
    .ref(window.location.hostname === HOSTING_DOMAIN ? "production": "development")