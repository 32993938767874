<script>
import allUsers from "stores/users"
import { tick } from "svelte"
import t from "stores/translations"
import { createUser } from "modules/users"
import { capitalize, some } from "lodash-es"

export let value = {
    name: undefined,
    id: undefined
}
export let placeholder = ""
let query = ""
let inputElement

$: matchedUsers = $allUsers.filter(user => (
    query && (new RegExp("^" + query, "i").test(user.name))
))

$: hasExactMatches = some($allUsers, user => user.name.toLowerCase() === query.toLowerCase())

function selectUser({id, name}) {
    mixpanel.track("selectUser")
    value = { id, name }
    query = ""
}

async function deselectUser() {
    mixpanel.track("deselectUser")
    query = value.name
    value = {
        name: undefined,
        id: undefined
    }

    await tick()
    inputElement.focus()
    inputElement.select()
}

function create() {
    mixpanel.track("createUser")
    const id = createUser(query)
    selectUser({
        id,
        name: capitalize(query)
    })
}

function onKeypress(event) {
    if (event.keyCode === 13) {
        if (matchedUsers.length === 1) {
            mixpanel.track("selectUserByPressingEnter")
            selectUser(matchedUsers[0])
        } else if (!matchedUsers.length && query.length > 1 && !hasExactMatches) {
            mixpanel.track("createUserByPressingEnter")
            create()
        } else {
            mixpanel.track("userInputPressEnter")
        }
    }
}
</script>

<style>
.user-picker {
    display: inline-block;
    position: relative;
    height: 36px;
    line-height: 35px;
    width: 100%;
}

input {
    width: 100%;
}

span {
    padding: 4px;
}

.autocomplete {
    position: absolute;
    left: 0;
    top: 36px;
    width: 100%;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.54);
    background-color: white;
    z-index: 10;
}

.autocomplete div {
    padding: 8px 4px;
    cursor: pointer;
}

.create {
    font-size: 16px;
    color: #545454;
}
</style>
<div class="user-picker">
    {#if value.name && value.id}
        <span on:click={deselectUser}>{value.name}</span>
    {:else}
        <input type="text" on:keypress={onKeypress} bind:value={query} bind:this={inputElement} autocomplete="off" class="ss-input" placeholder={placeholder}>
        <div class="autocomplete">
            {#each matchedUsers as user (user.id)}
                <div on:click={() => selectUser(user)}>{user.name}</div>
            {/each}
            {#if query.length > 1 && !hasExactMatches}
                <div class="create" on:click={create}>{$t.userInputCreateUser(query)}</div>
            {/if}
        </div>
    {/if}
</div>
