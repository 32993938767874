import { writable } from "svelte/store"
import friends from "./friends"
import { reject, some, includes } from "lodash-es"
import { getCurrentUser } from "modules/currentUser"

function createStore() {
    const { subscribe, update } = writable([], set => {
        const unsubscribe = friends.subscribe(async $friends => {
            const { id, lastDebitors } = await getCurrentUser()
            set($friends.filter(friend => {
                return friend.id === id || includes(lastDebitors, friend.id)
            }))
        })

        return () => {
            unsubscribe()
        }
    })

    return {
        subscribe,
        toggleUser(user) {
            update(relevantUsers => {
                if(some(relevantUsers, user)) {
                    mixpanel.track("reportsUncheckUser", {
                        relevantUsers: relevantUsers.length - 1
                    })
                    return reject(relevantUsers, user)
                } else {
                    mixpanel.track("reportsCheckUser", {
                        relevantUsers: relevantUsers.length + 1
                    })
                    return [...relevantUsers, user]
                }
            })
        }
    }
}

export default createStore()