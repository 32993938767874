import Fingerprint2 from "fingerprintjs2"
import queryString from "query-string"
import { get } from "lodash-es"

const FINGERPRINT_KEY = "LAST_FINGERPRINT"
const lastFingerprint = localStorage.getItem(FINGERPRINT_KEY)

export default function getFingerprint() {
    return new Promise(resolve => {
        const urlFingerprint = get(queryString.parse(window.location.search), "fp")
        if (urlFingerprint) {
            resolve(urlFingerprint)
        } else if (lastFingerprint) {
            resolve(lastFingerprint)
        } else {
            if ("requestIdleCallback" in window) {
                window.requestIdleCallback(createFingerprint)
            } else {
                setTimeout(createFingerprint, 1000)
            }
        }

        function createFingerprint() {
            Fingerprint2.get(components => {
                let fingerprintHash = Fingerprint2.x64hash128(components.map(c => c.value).join("", 31))
                localStorage.setItem(FINGERPRINT_KEY, fingerprintHash)
                resolve(fingerprintHash)
            })
        }
    })
}
