import db from "./firebase-db"
import { map, capitalize } from "lodash-es"

const usersRef = db.child("users")

export function createUser(name) {
    const newUser = usersRef.push()
    newUser.set({
        name: capitalize(name),
        newsLevel: 0
    })
    return newUser.key
}

export function getAllUsers() {
    return new Promise(resolve => {
        usersRef.once("value", snapshot => resolve(map(snapshot.val(), (user, userId) => ({
            name: user.name,
            id: userId
        }))))
    })
}
