<script>
import t from "stores/translations"
import mainView from "stores/mainView"
import { slide } from "svelte/transition"
import { onMount } from "svelte"
// import getCurrencies from "modules/currencyAPI"
import currencies from "modules/currencyHardcoded"
import currency from "stores/currency"
import { find } from "lodash-es"
import { balanceModeSetting } from "stores/relevantBalance"

const LANGUAGE_KEY = "LANGUAGE"

let selectedCurrency
let allCurrencies = currencies
let selectedLanguage = localStorage.getItem(LANGUAGE_KEY) || "de"

onMount(async () => {
    // allCurrencies = await getCurrencies()
    selectedCurrency = $currency.symbol
})

function onCurrencyChanged(event) {
    selectedCurrency = event.target.value
    mixpanel.track("changeCurrency")
    mixpanel.register({ currency: selectedCurrency })
    
    const currencyObject = find(allCurrencies, { symbol: selectedCurrency })
    currency.setCurrency(currencyObject)
}

function onLanguageChanged(event) {
    selectedLanguage = event.target.value
    t.changeLanguage(selectedLanguage)
}

function onBalanceModeChanged(event) {
    balanceModeSetting.set(event.target.value)
}
</script>

<style>
.setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
}

.setting.small {
    font-size: 16px;
}

.setting.small select {
    text-align-last: right;
}
</style>

<div class="card">
    <div class="card__title">
        <span>{$t.settingsTitle}</span>
        <i class="material-icons" on:click={mainView.toggleSettings}>close</i>
    </div>
    <div class="card__content" transition:slide|local={{ duration: 250}}>
        <div class="setting">
            <label>{$t.currency}</label>
            <select value={selectedCurrency} on:change={onCurrencyChanged} class="ss-select">
                {#each allCurrencies as currency (currency.symbol)}
                    <option value={currency.symbol}>{currency.symbol}{currency.img}</option>
                {/each}
            </select>
        </div>
        <div class="setting">
            <label>{$t.language}</label>
            <select value={selectedLanguage} on:change={onLanguageChanged} class="ss-select">
                <option value="en">{$t.languageEnglish}🇬🇧</option>
                <option value="de">{$t.languageGerman}🇩🇪</option>
            </select>
        </div>
        <div class="setting small">
            <label>{$t.relevantBalance}</label>
            <select value={$balanceModeSetting} on:change={onBalanceModeChanged} class="ss-select">
                <option value="all">{$t.balanceModeAll}</option>
                <option value="lastDebitors">{$t.balanceModeLastDebitors}</option>
            </select>
        </div>
    </div>
</div>
