<script>
import { find } from "lodash-es"
import users from "stores/users"
import t from "stores/translations"
export let userId

$: user = find($users, { id: userId }) || {}
</script>

{user.name || $t.removedUser}
