<script>
import t from "stores/translations"
</script>

<style>
.introduction {
    margin-top: 52px;
    display: flex;
    justify-content: center;
    padding: 16px;
    background-color: #eeeeee;
}

.introduction__language span {
    font-size: 20px;
    cursor: pointer;
}
</style>

<div class="introduction">
    <div>
        <div class="introduction__language">
            <span on:click={() => t.changeLanguage("de")}>Deutsch</span>
            <span>|</span>
            <span on:click={() => t.changeLanguage("en")}>English</span>
        </div>
        <h1>EXPENSE SPLITTING.</h1>
        <h2>SIMPLE AS SHIT.</h2>
    </div>
    
</div>