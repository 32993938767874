import { readable } from "svelte/store"
import db from "modules/firebase-db"

export default readable({}, set => {
    const newsRef = db.child("news")

    newsRef.on("value", snapshot => {
        set(snapshot.val())
    })

    return () => {
        newsRef.off()
    }
})