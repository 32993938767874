import { readable } from "svelte/store"
import db from "modules/firebase-db"
import { map, sumBy } from "lodash-es"

export default readable([], set => {
    const expensesRef = db.child("expenses")

    expensesRef.orderByKey().on("value", snapshot => {
        set(map(snapshot.val(), (expense, id) => ({
            ...expense,
            id,
            amount: sumBy(expense.debitors, "amount")
        })).filter(expense => (
            !expense.deleted
        )).reverse())
    })

    return () => {
        expensesRef.off()
    }
})