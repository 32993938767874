<script>
import { createNewCurrentUser, linkCurrentUser } from "modules/currentUser"
import t from "stores/translations"
import { allUsersFullInfo } from "stores/users"
import { some, isEmpty } from "lodash-es"
import Spinner from "./Spinner.svelte"
import { onMount } from "svelte"

onMount(() => mixpanel.track("NameSignUp"))

let name = ""
let loading = false

$: matchedUsers = $allUsersFullInfo.filter(user => (
    name && (new RegExp("^" + name, "i").test(user.name))
))

$: hasExactMatches = some($allUsersFullInfo, user => user.name.toLowerCase() === name.toLowerCase())

function login(user) {
    if (user) {
        loading = true
        linkCurrentUser(user)
    } else if (!hasExactMatches) {
        loading = true
        createNewCurrentUser(name)
    }
}
</script>

<style>
.name-sign-up {
    display: flex;
    justify-content: center;
}

form {
    width: 100%;
    max-width: 480px;
    margin: 0;
    padding: 16px;
    display: flex;
    flex-direction: column;
}

form input {
    margin: 16px;
}

.autocomplete {
    margin: 0 16px;
}

.autocomplete div {
    padding: 8px 4px;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #eeeeee;
    cursor: pointer;
}

.autocomplete div i {
    color: #ff0000;
}

.autocomplete div i.available {
    color: #217325;
}

.hint {
    margin: -8px 20px 0 20px;
    font-size: 16px;
    color: #545454;
}

</style>

<div class="name-sign-up">
    {#if loading}
        <Spinner/>
    {:else}
        <form on:submit|preventDefault={() => login()}>
            <div class="autocomplete">
                {#each matchedUsers as user (user.id)}
                    <div on:click={() => isEmpty(user.devices) && login(user)}>
                        <span>{user.name}</span>
                        <i class="material-icons" class:available={isEmpty(user.devices)}>{isEmpty(user.devices) ? "check" : "close"}</i>
                    </div>
                {/each}
            </div>
            <input type="text" placeholder={$t.nameInputPlaceholder} bind:value={name} class="ss-input" autocomplete="off">
            {#if matchedUsers.length}
                <div class="hint">{$t.nameInputHint}</div>
            {/if}
            <input type="submit" class="ss-button" value={$t.signUpButton}>
        </form>
    {/if}

</div>
