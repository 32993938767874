<script>
import t from "stores/translations"
import currentUser from "stores/currentUser"
import getUserAvatarColor from "modules/userAvatarColor"
import currency from "stores/currency"

$: avatarColor = $currentUser && getUserAvatarColor($currentUser.id)

function scrollUp() {
    mixpanel.track("clickHeader")
    window.scroll({
        top: 0,
        behavior: "smooth"
    })
}
</script>

<style>
header {
    background-color: black;
    padding: 8px 16px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 20;
}

header h1 {
    padding: 0;
    margin: 0;
}

.avatar {
    width: 24px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    line-height: 24px;
    padding: 4px;
    font-weight: bold;
}

:global(header span) {
    font-size: 20px;
}
</style>

<header on:click={scrollUp}>
    <h1>💩 {$t.headerTitle}</h1>
    <div>
        <span on:click|stopPropagation={currency.quickSwitch}>{$currency.symbol}</span>
        {#if $currentUser}
            <span class="avatar" style={`background-color: ${avatarColor}`}>{$currentUser.name[0]}</span>
        {/if}
    </div>
    
</header>