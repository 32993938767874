<script>
import { createEventDispatcher } from "svelte"
export let text

const dispatch = createEventDispatcher()
</script>

<style>
div {
    margin-bottom: 16px;
    cursor: pointer;
}
</style>

<div class="card__title" on:click={e => dispatch("click", e)}>
    <span>{text}</span>
    <i class="material-icons">keyboard_arrow_down</i>
</div>