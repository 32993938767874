<script>
import { slide } from "svelte/transition"
import AmountDisplay from "./AmountDisplay.svelte"
import ExpenseListItemActions from "./ExpenseListItemActions.svelte"
import currentUser from "stores/currentUser"
import UserNameDisplay from "./UserNameDisplay.svelte"
import TimeAgo from "./TimeAgo.svelte"
import t from "stores/translations"

export let expense
let expanded = false
$: purpose = calculatePurpose(expense.purpose)

function toggle() {
    mixpanel.track("expenseListToggleItem")
    expanded = !expanded
}

function calculatePurpose(p) {
    if(p === "$$MONEY_LENT$$" || p === "$$MONEY_BORROWED$$") {
        return expense.paid_by.id === $currentUser.id ? $t.expensePurposeLent : $t.expensePurposeBorrowed
    } else if (!p) {
        return (new Date(expense.datetime)).toLocaleDateString()
    } else {
        return p
    }
} 
</script>

<style>
.expense-list-item {
    border-top: solid 1px #dddddd;
    padding: 8px 0;
}

.expense-list-item__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expense-list-item__title span {
    font-size: 20px;
}

.expense-list-item__amount {
    white-space: nowrap;
}

.expense-details {
    padding: 8px 0;
    color: #545454;
    display: flex;
    justify-content: space-between;
}

.expense-details span {
    font-size: 16px;
}

.debitor {
    display: flex;
    justify-content: flex-end;
}

.creditor {
    display: flex;
    align-items: center;
}

.creditor i {
    margin-right: 8px;
}
</style>

<div class="expense-list-item" transition:slide|local={{ duration: 250}} >
    <div class="expense-list-item__title" on:click={toggle}>
        <span>{purpose}</span>
        <span class="expense-list-item__amount">
            <AmountDisplay amount={expense.amount}/>
        </span>
    </div>

    {#if expanded}
        <div transition:slide|local={{ duration: 250}} class="expense-details">
            <div>
                <div class="creditor">
                    <i class="material-icons">credit_card</i>
                    <span><UserNameDisplay userId={expense.paid_by.id}/></span>
                </div>
                <div class="creditor">
                    <i class="material-icons">access_time</i>
                    <span><TimeAgo datetime={expense.datetime}/></span>
                </div>
            </div>
            <div>
                {#each expense.debitors as debitor (debitor.user.id)}
                    <div class="debitor">
                        <span><UserNameDisplay userId={debitor.user.id}/></span>
                        <span>&nbsp;</span>
                        <span><AmountDisplay amount={debitor.amount}/></span>
                    </div>
                {/each}
            </div>
        </div>
        {#if expense.author === $currentUser.id}
            <ExpenseListItemActions expense={expense}/>
        {/if}
    {/if}
</div>

