import db from "./firebase-db"
import { omit, round } from "lodash-es"
import { getCurrentUser } from "./currentUser"

function transformExpense(expense, currency) {
    // TODO: test
    return {
        ...expense,
        datetime: (new Date()).toISOString(),
        paid_by: {
            name: expense.paid_by.name,
            id: expense.paid_by.id
        },
        debitors: expense.debitors.map(debitor => ({
            ...debitor,
            amount: round(debitor.amount / currency.rate, 2)
        }))
    }
}

export const saveExpense = (expense, currency) => {
    mixpanel.track("saveExpense", {
        debitors: expense.debitors.length,
        purpose: !!expense.purpose
    })
    setLastDebitors(expense.debitors)

    return new Promise((resolve, reject) => {
        const newExpense = db.child("expenses").push()
        newExpense.set(
            omit(transformExpense(expense, currency), "amount"),
            error => error ? reject(error) : resolve()
        )
    })
}

export const removeExpense = expense => {
    mixpanel.track("removeExpense")
    const expenseInDb = db.child("expenses/" + expense.id)
    expenseInDb.update({
        deleted: true
    })
}

async function setLastDebitors(debitors) {
    const currentUser = await getCurrentUser()
    db.child(`users/${currentUser.id}/lastDebitors`).set(debitors.map(({user}) => user.id))
}