<script>
import addExpense from "stores/addExpense"
import mainView from "stores/mainView"
import { saveExpense } from "modules/expense"
import DebitorsInput from "./DebitorsInput.svelte"
import UserInput from "./UserInput.svelte"
import t from "stores/translations"
import { slide } from "svelte/transition"
import currency from "stores/currency"
import { isEmpty } from "lodash-es"

async function submit() {
    if (!isEmpty($addExpense.debitors)) {
        await saveExpense($addExpense, $currency)
        addExpense.reset()
        mainView.closeExpenseInput()
    }
}

function changeAmount(event) {
    addExpense.setTotalAmount(Number(event.target.value))
}
</script>

<style>
form {
    margin: 0;
}

.add-expense-field {
    margin: 16px 0;
    display: flex;
    align-items: center;
}

.purpose-input {
    flex: 1 1 auto;
}

.ss-button {
    width: 100%;
    margin: 16px 0;
}

span {
    white-space: nowrap;
    min-width: 120px;
}

.currency {
    color: #757575;
}
</style>

<form transition:slide|local={{ duration: 250}}>
    <div class="add-expense-field">
        <span>{$t.addExpenseFieldTotal}</span>
        <input class="ss-input" type="number" value={$addExpense.amount} on:input={changeAmount} min="0" step="0.01" required>
        <span class="currency" on:click={mainView.openSettings}>{$currency.symbol}{$currency.img || ""}</span>
    </div>

    <div class="add-expense-field">
        <span>{$t.addExpenseFieldCreditor}</span>
        <UserInput bind:value={$addExpense.paid_by}/>
    </div>

    <div class="add-expense-field">
        <span>{$t.addExpenseFieldPurpose}</span>
        <input class="ss-input purpose-input" type="text" bind:value={$addExpense.purpose} placeholder={$t.addExpenseFieldPurposePlaceholder}>
    </div>
    
    <DebitorsInput/>

    <div>
        <input type="button" on:click={submit} value={$t.save} class="ss-button">
    </div>
</form>
