<script>
import UserInput from "./UserInput.svelte"
import addExpenseStore from "stores/addExpense"

export let debitor

function changeAmount(event) {
    addExpenseStore.setDebitorAmount(debitor, Number(event.target.value))
}
</script>

<style>
i {
    cursor: pointer;
    color: #545454;
}

.debitor-input {
    background-color: #eeeeee;
    border-radius: 40px;
    padding: 8px;
    margin: 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.debitor-input__left {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
}
</style>

<div class="debitor-input">
    <div class="debitor-input__left">
        <i class="material-icons" on:click={() => addExpenseStore.removeDebitor(debitor)}>close</i>
        <UserInput bind:value={debitor.user}/>
    </div>
    <!-- because bind:value would just update the object property which is not recognized in the reactive chain-->
    <input type="number" value={debitor.amount} on:input={changeAmount} min="0" step="0.01" class="ss-input">
</div>