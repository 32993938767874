<script>
import addCreditor from "stores/addCreditor"
import mainView from "stores/mainView"
import { saveExpense } from "modules/expense"
import UserInput from "./UserInput.svelte"
import t from "stores/translations"
import { slide } from "svelte/transition"
import currency from "stores/currency"
import { get } from "lodash-es"

async function submit() {
    if (get($addCreditor.paid_by, "id")) {
        await saveExpense($addCreditor, $currency)
        addCreditor.reset()
        mainView.toggleIsAddingCreditor()
    }
}

function changeAmount(event) {
    addCreditor.setTotalAmount(Number(event.target.value))
}
</script>

<style>
form {
    margin: 0;
}

.add-expense-field {
    margin: 16px 0;
    display: flex;
    align-items: center;
}

.ss-button {
    width: 100%;
    margin: 16px 0;
}

span {
    white-space: nowrap;
    min-width: 120px;
}

.currency {
    color: #757575;
}
</style>

<form class="card__content"  transition:slide|local={{ duration: 250}}>
    <div class="add-expense-field">
        <span>{$t.addCreditorFieldTotal}</span>
        <input class="ss-input" type="number" value={$addCreditor.amount} on:input={changeAmount} min="0" step="0.01" required>
        <span class="currency" on:click={mainView.openSettings}>{$currency.symbol}{$currency.img || ""}</span>
    </div>

    <div class="add-expense-field">
        <span>{$t.addCreditorFieldCreditor}</span>
        <UserInput bind:value={$addCreditor.paid_by}/>
    </div>

    <div>
        <input type="button" on:click={submit} value={$t.save} class="ss-button">
    </div>
</form>
