<script>
import t from "stores/translations"
import mainView from "stores/mainView"
import AddExpense from "./AddExpense.svelte"
import AddDebitor from "./AddDebitor.svelte"
import AddCreditor from "./AddCreditor.svelte"

$: title = getTitle($mainView)
$: isAnythingOpen = $mainView.isAddingExpense || $mainView.isAddingDebitor || $mainView.isAddingCreditor

function getTitle({ isAddingExpense, isAddingDebitor, isAddingCreditor }) {
    if (isAddingExpense) {
        return $t.addExpenseTitle
    } else if (isAddingDebitor) {
        return $t.addDebitorTitle
    } else if (isAddingCreditor) {
        return $t.addCreditorTitle
    } else {
        return $t.callToActionTitle
    }
}
</script>

<style>
.item {
    padding: 16px;
    border-bottom: solid 1px #eeeeee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.item span {
    font-size: 20px;
}

.item i {
    color: #545454;
}
</style>

<div class="card">
    <div class="card__title">
        <span>{title}</span>
        {#if isAnythingOpen}
            <i class="material-icons" on:click={mainView.callToActionCloseAll}>close</i>
        {/if}
    </div>

    {#if !isAnythingOpen}
        <div>
            <div class="item" on:click={mainView.toggleIsAddingExpense}>
                <span>{$t.callToActionAddExpense}</span>
                <i class="material-icons">arrow_forward_ios</i>
            </div>
            <div class="item" on:click={mainView.toggleIsAddingDebitor}>
                <span>{$t.callToActionAddDebitor}</span>
                <i class="material-icons">arrow_forward_ios</i>
            </div>
            <div class="item" on:click={mainView.toggleIsAddingCreditor}>
                <span>{$t.callToActionAddCreditor}</span>
                <i class="material-icons">arrow_forward_ios</i>
            </div>
        </div>
    {:else if $mainView.isAddingExpense}
        <div class="card__content">
            <AddExpense/>
        </div>
    {:else if $mainView.isAddingDebitor}
        <AddDebitor/>
    {:else if $mainView.isAddingCreditor}
        <AddCreditor/>
    {/if}
</div>