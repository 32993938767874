<script>
import news from "stores/news"
import currentUser from "stores/currentUser"
import { setNewsLevel } from "modules/currentUser"
import { slide } from "svelte/transition"

function dismiss() {
    setNewsLevel($currentUser, $news.level)
}
</script>

<style>
.news {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px 12px 12px;
}

.message {
    font-size: 14px;
}

i {
    cursor: pointer;
}
</style>

{#if $news.msg && $currentUser.newsLevel < $news.level}
<div class="news" transition:slide|local={{ duration: 250}}>
    <div class="message">{$news.msg}</div>
    <i class="material-icons" on:click={dismiss}>done</i>
</div>
{/if}
