<script>
import expenseList from "stores/expenseList"
import ExpenseListItem from "./ExpenseListItem.svelte"
import { slide } from "svelte/transition"
import { take } from "lodash-es"
import t from "stores/translations"

let limit = 3
$: expensePage = take($expenseList, limit)

function showMore() {
    mixpanel.track("expenseListShowMore")
    limit += 5
}
</script>

<style>
.more {
    text-align: center;
    font-size: 16px;
    color: #545454;
    padding: 16px;
    cursor: pointer;
}
</style>

<div transition:slide|local={{ duration: 250}}>
    {#each expensePage as expense}
        <ExpenseListItem expense={expense}/>
    {/each}
    {#if expensePage.length < $expenseList.length}
        <div class="more" on:click={showMore}>{$t.expenseListShowMore}</div>
    {/if}
</div>
