<script>
import t from "stores/translations"
import relevantBalance from "stores/relevantBalance"
import AmountDisplay from "./AmountDisplay.svelte"
import ExpenseList from "./ExpenseList.svelte"
import mainView from "stores/mainView"
import { slide } from "svelte/transition"
import AddExpense from "./AddExpense.svelte"
import { balanceModeSetting } from "stores/relevantBalance"
import relevantUsers from "stores/reports"
import { map } from "lodash-es"

$: hintText = map($relevantUsers, "name").join(", ")
</script>

<style>
h1 {
    text-align: center;
}

.negative {
    color: #ff0000
}

.negative-background {
    background-image: linear-gradient(0, transparent, transparent, rgba(255, 0, 0, 0.1));
}

.positive {
    color: #217325
}

.positive-background {
    background-image: linear-gradient(0, transparent, transparent, rgba(32, 115, 37, 0.1));
}

.create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    color: #545454;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.create div {
    display: flex;
    align-items: center;
}

.create span {
    font-size: 20px;
}

.create i {
    color: #545454;
    cursor: pointer;
}

.info {
    font-size: 14px;
    color: #545454;
}
</style>

<div class="card">
    <div class="card__title">
        <span>{$t.balanceTitle}</span>
    </div>
    <div class="card__content" class:negative-background={$relevantBalance < 0} class:positive-background={$relevantBalance > 0}>
        {#if $balanceModeSetting === "lastDebitors"}
            <span class="info">{$t.balanceHint} {hintText}</span>
        {/if} 
        <h1 class:negative={$relevantBalance < 0} class:positive={$relevantBalance > 0}>
            <AmountDisplay amount={$relevantBalance}/>
        </h1>
        <div class="create" on:click={mainView.toggleIsAddingExpenseInWidget} transition:slide|local={{ duration: 250}}>
            <div>
                <i class="material-icons">add</i>
                <span>{$t.addExpenseButton}</span>
            </div>
            {#if $mainView.isAddingExpenseInWidget}
                <i class="material-icons">close</i>
            {/if}
        </div>
        {#if $mainView.isAddingExpenseInWidget}
            <AddExpense/>
        {/if}
        <ExpenseList/>
    </div>
</div>

