import { writable } from "svelte/store"
import { getCurrentUser } from "modules/currentUser"

function createStore() {
    const { subscribe, update, set } = writable({
        purpose: "$$MONEY_LENT$$",
        amount: 0,
        paid_by: {},
        authorId: null,
        debitors: [{
            amount: 0,
            user: {
                name: null,
                id: null
            }
        }]
    }, set => {
        setInitial(set)
    })

    async function setInitial(set) {
        const { id, name } = await getCurrentUser()
        set({
            purpose: "$$MONEY_LENT$$",
            amount: 0,
            paid_by: { id, name },
            author: id,
            debitors: [{
                amount: 0,
                user: {
                    name: null,
                    id: null
                }
            }]
        })
    }

    function setTotalAmount(amount) {
        update(state => ({
            ...state,
            amount,
            debitors: state.debitors.map(debitor => ({
                ...debitor,
                amount
            }))
        }))
    }

    return {
        subscribe,
        set,
        update,
        reset: () => setInitial(set),
        setTotalAmount
    }
}

export default createStore()