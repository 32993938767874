import { readable, derived } from "svelte/store"
import db from "modules/firebase-db"
import { map } from "lodash-es"

export const allUsersFullInfo = readable([], set => {
    db.child("users").on("value", snapshot => {
        set(map(snapshot.val(), (user, id) => ({
            ...user,
            id
        })))
    })

    return () => {
        db.child("users").off()
    }
})

export default derived(allUsersFullInfo, $allUsersFullInfo => {
    return map($allUsersFullInfo, ({name, id}) => ({ name, id }))
})