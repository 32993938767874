import { derived } from "svelte/store"
import expenses from "./expenses"
import currentUser from "./currentUser"
import { sumBy, filter, get } from "lodash-es"

export default derived([expenses, currentUser], ([$expenses, $currentUser]) => {
    const currentUserId = get($currentUser, "id")
    const amountPaid = sumBy(filter($expenses, e => e.paid_by.id === currentUserId), "amount")
    const amountConsumed = sumBy($expenses, e => (
        sumBy(e.debitors, d => (
            d.user.id === currentUserId ? d.amount : 0
        ))
    ))

    return amountPaid - amountConsumed
})