import { derived } from "svelte/store"
import expenses from "./expenses"
import currentUser from "./currentUser"
import { get, some } from "lodash-es"

export default derived([expenses, currentUser], ([$expenses, $currentUser]) => {
    const currentUserId = get($currentUser, "id")
    return $expenses.filter(expense => (
        expense.paid_by.id === currentUserId || some(expense.debitors, debitor => (
            debitor.user.id === currentUserId
        ))
    ))
})