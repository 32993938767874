const currencies = [
  {
    rate: 1.00,
    symbol: "CHF",
    img: "🇨🇭"
  },
  {
    rate: 0.93,
    symbol: "EUR",
    img: "🇪🇺"
  },
]

export default currencies