const colors = [
    "#ca0025",
    "#ca6700",
    "#caa800",
    "#6aca00",
    "#00ca76",
    "#00b7ca",
    "#005aca",
    "#2800ca",
    "#7300ca",
    "#ca00a8"
]

export default function(userId) {
    let sum = 0
    userId.split("").forEach((character, index) => {
        sum += userId.charCodeAt(index)
    })
   
    return colors[sum % 10]
}