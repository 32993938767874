import { derived } from "svelte/store"
import allBalances from "./allBalances"
import { forEach, forEachRight, cloneDeep } from "lodash-es"

export default derived(allBalances, $allBalances => {
    const payments = []
    const allBalancesCopy = cloneDeep($allBalances)

    forEach(allBalancesCopy, richKid => {
        forEachRight(allBalancesCopy, poorKid => {
            const amount = Math.min(Math.abs(richKid.balance), Math.abs(poorKid.balance))
            poorKid.balance += amount
            richKid.balance -= amount

            // because floating point precision
            if (amount > 0.01) {
                payments.push({
                    from: poorKid,
                    to: richKid,
                    amount
                })
            }
        })
    })

    return payments
})