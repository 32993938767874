import { derived, writable } from "svelte/store"
import currentUser from "./currentUser"
import balance from "./balance"
import allBalances from "./allBalances"
import { get, find } from "lodash-es"

const BALANCE_MODE_KEY = "BALANCE_MODE"

function createStore() {
    const { subscribe, set } = writable(
        localStorage.getItem(BALANCE_MODE_KEY) || "all"
    )

    return {
        subscribe,
        set(mode) {
            mixpanel.track("changeRelevantBalance")
            localStorage.setItem(BALANCE_MODE_KEY, mode)
            return set(mode)
        }
    }
}

export const balanceModeSetting = createStore()

export default derived([balance, allBalances, currentUser, balanceModeSetting], ([$balance, $allBalances, $currentUser, $setting]) => {
    if ($setting === "lastDebitors") {
        const currentUserId = get($currentUser, "id")
        return get(find($allBalances, user => user.id === currentUserId), "balance") || 0
    } else {
        return $balance
    }
})