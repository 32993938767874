<script>
	import currentUser from "stores/currentUser"
	import Header from "components/Header.svelte"
	import NameSignUp from "components/NameSignUp.svelte"
	import MainView from "components/MainView.svelte"
	import Spinner from "components/Spinner.svelte"
	import Introduction from "components/Introduction.svelte"

	$: userExists = $currentUser === undefined || $currentUser
</script>

<style>
:global(html *) {
	font-family: 'Roboto', sans-serif;
}

:global(.ss-button) {
	background-color: black;
	color: white;
	text-transform: uppercase;
	font-weight: 500;
	border: none;
	padding: 16px 24px;
	cursor: pointer;
	font-size: 20px;
	outline: none;
}

:global(.ss-input) {
	border: none;
    outline: none;
    border-bottom: solid 1px #eeeeee;
    font-size: 24px;
    padding: 4px;
	background-color: transparent;
	min-width: 0;
}

:global(.ss-input:focus) {
    border-bottom-color: black;
}

:global(.ss-input[type=number]) {
	width: 100px;
}

:global(div, span, input) {
	font-size: 24px;
}

:global(.card) {
	background-color: white;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.54);
	margin-bottom: 16px;
}

:global(.card__title) {
	background-color: #eeeeee;
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

:global(.card__title span) {
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
}

:global(.card__title i) {
	color: #545454;
	cursor: pointer;
}

:global(.card__content) {
	padding: 8px 16px;
}

:global(.ss-select) {
	outline: none;
    border: none;
    background-color: transparent;
}

div {
	height: 100vh;
	display: flex;
	justify-content: center;
}
</style>

<Header/>
{#if $currentUser}
	<MainView/>
{:else if !userExists}
	<Introduction/>
	<NameSignUp/>
{:else}
	<div>
		<Spinner/>
	</div>
{/if}
