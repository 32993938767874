import { derived } from "svelte/store"
import expenseList from "./expenseList"
import { map, uniqBy, flatten, orderBy } from "lodash-es"

export default derived(expenseList, $expenseList => {
    const debitors = map(
        uniqBy(
            flatten(
                map($expenseList, "debitors")
            ), debitor => debitor.user.id
        ), "user"
    )
    const creditors = uniqBy(map($expenseList, "paid_by"), creditor => creditor.id)

    return orderBy(
        uniqBy(
            map([...creditors, ...debitors], ({name, id}) => ({ name, id })),
            "id"
        ),
        "name"
    )
})